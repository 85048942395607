@import '@styles/variables.scss';

.custom404 {
  height: 100vh;
}

.wrapper {
  display: flex;
  justify-content: space-evenly;

  img {
    height: 400px;
  }
}

.content {
  align-self: center;
}
